import request from '@/api/request.js'
// 这里面存关于检测方案的请求
//   ------------左边
// 查询检测方案列表
export function getMonitorList(data) {
  return request({
    url: 'api/monitor/monitor/list',
    method: 'post',
    data
  })
}
// 条件查询方案列表
export function findAllIos(data) {
  return request({
    url: 'api/monitor/monitor/findAllIos',
    method: 'post',
    data
  })
}
// 条件查询方案列表·改
// export function findAllIos(data) {
//   return request({
//     url: 'api/monitor/monitor/findAllPC',
//     method: 'post',  
//     data
//   })
// }
// 查询机构上级方案·
export function getTopList(data) {
  return request({
    url: 'api/monitor/monitor/topList',
    method: 'post',
    data
  })
}
// 新增机构监测方案
export function addMonitor(data) {
  return request({
    url: 'api/monitor/monitor/add',
    method: 'post',
    data
  })
}
//  修改检测方案
export function editMonitor(data) {
  return request({
    url: "api/monitor/monitor/edit",
    method: "post",
    data
  })
}
// 获取监测方案详情
export function detailMonitor(data) {
  return request({
    url: "api/monitor/monitor/query",
    method: "post",
    data
  })
}
// 获取监测数量
export function monitorNum(organizationId) {
  return request({
    url: "api/monitor/monitor/getOrganLimit/" + organizationId,
    method: "get"
  })
}
// 获取公共监测方案详情
export function publicQuer(data) {
  return request({
    url: "api/monitor/monitor/publicQuery",
    method: "post",
    data
  })
}
// 删除监测方案
export function removeMonitor(data) {
  return request({
    url: "api/monitor/monitor/remove",
    method: "post",
    data
  })
}
// 增加词组
export function revKeywordGroup(data) {
  return request({
    url: "api/monitor/monitor/instartKeywordGroup",
    method: "post",
    data
  })
}
// 增加定向监测
export function instartDirectionalMonitor(data) {
  return request({
    url: "api/monitor/monitor/instartDirectionalMonitor",
    method: "post",
    data
  })
}
//修改方案运行状态
export function updateRun(data) {
  return request({
    url: "api/monitor/monitor/updateRun",
    method: "post",
    data
  })
}
//  ----------右边
// 查询监测数据
export function findAllData(data, url) {
  return request({
    url: "api/monitor/monitor/findAllData" + url,
    method: "post",
    data
  })
}
// 获取监测数据详情信息 /点击检测数据获取详情
export function selectDataById(data) {
  return request({
    url: "api/monitor/monitor/selectDataById",
    method: "post",
    data
  })
}
// 跟评筛选条件 
export function selectConditon(data) {
  return request({
    url: "api/monitor/monitor/selectConditon",
    method: "post",
    data
  })
}
// 刪除指定监测方案数据
export function deleteDataById(data) {
  return request({
    url: "api/monitor/monitor/deleteDataById",
    method: "post",
    data
  })
}
// 首页监测方案列表
export function monitorSchemeList(data) {
  return request({
    url: "api/monitor/monitor/MonitorSchemeList",
    method: "post",
    data: data
  })
}
// 境外筛选条件
export function topList(data) {
  return request({
    url: "api/msgquery/data/topList",
    method: "post",
    data: data
  })
}
// 表达式检查
export function expressionMatch(data) {
  return request({
    url: "expressionMatch",
    method: "post",
    data: data
  })
}
// 表达式检查新
export function verifyKeywordExpression(data) {
  return request({
      url: "keywordMatch/verifyKeywordExpression",
      method: "post",
      data: data
  })
}
