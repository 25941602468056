// 精准舆情的接口
import request from '@/api/request.js'
export function accurateList (data) {
  return request({
    url: 'api/msgquery/sentiment/listWeb',
    method: 'post',
    data: data
  })
} 
// 首页index列表
export function selectWarning (organId) {
  return request({
    url: 'api/msgquery/sentiment/selectWarningQuery',
    method: 'get',
    params: organId
  })
}
export function accurateInfo (data) {
    return request({
      url: 'api/msgquery/sentiment/findById',
      method: 'post',
      data: data
    })
  }
  // 评论列表
  export function comment (data) {
    return request({
      url: 'api/msgquery/sentiment/export',
      method: 'get',
      params: data,
      responseType: "blob",
    })
  }
  export function commentList (data) {
    return request({
      url: 'api/msgquery/sentiment/commentListWeb',
      method: 'post',
      data: data
    })
  }
//   评论
  export function commentCount (data) {
    return request({
      url: 'api/msgquery/sentiment/commentCount',
      method: 'post',
      data: data
    })
  }
//   精准舆情条件查询
export function conditionList (data) {
  return request({
    url: 'api/system/regional/preciseQuery',
    method: 'post',
    data: data
  })
}
// 设置标签
export function setLabel (data) {
  return request({
    url: 'api/msgquery/label/list',
    method: 'get',
    params: data
  })
}
// 标签详情
export function getLabelInfo (labelId) {
  return request({
    url: `api/msgquery/label/${labelId}`,
    method: 'get'
  })
}
// 修改标签
export function modifyLabel (data) {
  return request({
    url: 'api/msgquery/sentiment/edit',
    method: 'post',
    data:data
  })
}
// 删除
export function deleteAccurate (data) {
  return request({
    url: 'api/msgquery/sentiment/remove',
    method: 'post',
    data:data
  })
}
// 页面截图
export function screenShot (data) {
  return request({
    url: 'api/msgquery/screen/screenShot',
    method: 'post',
    data:data
  })
}
// 页面截图获取状态
export function screenShotState (data) {
  return request({
    url: 'api/msgquery/screen/getScreenShot',
    method: 'post',
    data:data
  })
}
// 收藏精准舆情

export function sentimentEdit (data) {
  return request({
    url: 'api/msgquery/sentiment/edit',
    method: 'post',
    data:data
  })
}
// 取消标签
export function deleteLabel (data) {
  return request({
    url: 'api/msgquery/sentiment/lable',
    method: 'post',
    data:data
  })
}
// 相似列表
export function likeList (data) {
  return request({
    url: 'api/msgquery/sentiment/likeList',
    method: 'post',
    data:data
  })
}
// 评论总数
export function commitNum (data) {
  return request({
    url: 'api/msgquery/sentiment/commentSum?accurateId='+data,
    method: 'get'
  })
}


