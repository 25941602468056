<template>
  <div class="deleteDialog">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-if="dataObj.keys">
        <p>是否确认删除当前观点</p>
        <p>删除任务执行中请勿进行数据筛选，否则数据会有误差。</p>
      </div>
      <span v-else>此操作将永久删除{{dataObj.message}}, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureClick">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return { 
      dialogVisible: false,
      dataObj: {},
      type: ""
    };
  },
  props: {},
  methods: {
    handleClose(done) {
      done();
    },
    openDialog(data, type) {
      this.dialogVisible = true;
      this.dataObj = data;
      this.type = type;
    },
    sureClick() {
      if (this.type == "媒体观点") {
        this.$emit("deleteMediaList", this.dataObj);
      } else {
        this.$emit("deleteFn", this.dataObj);
      }
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
</style>