<template>
  <div class="information">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="goback">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item>
        <span @click="goback" style="cursor: pointer">监测方案</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div class="information-box">
      <div class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div v-if="dataList.setType == 0" class="main-top-center">
            <!-- <p v-html="textData.titleTraslation" class="center-title">{{ textData.titleTraslation }}</p> -->
            <div class="yujing-box">
              <div v-if="textData.isEarlyWarn == 1" class="yujing">已预警</div>

              <p class="center-title" v-html="textData.title ? textData.title : ''"></p>
            </div>

            <div class="caozuo-center">
              <div class="type english">{{ textData.languageType }}</div>
              <div class="type website">{{ textData.mediaType }}</div>
              <a :href="textData.website" target="_blank" class="original original-color">
                <span>
                  <img src="../../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </a>
              <!-- <span class="original comment-weidu">
                <span>
                  <img
                    v-if="textData.isRead == '1'"
                    src="../../assets/img/views/greenmessage.png"
                    alt
                  />
                  <img v-else src="../../assets/img/views/redmessage.png" alt />
                </span>
                <span :class="[textData.isRead == 1 ? 'yidu' : ' ']">
                  {{
                  textData.isRead == "1" ? "已读" : "未读"
                  }}
                </span>
              </span>-->
              <div style="margin-left: 16px" class="center-timer">
                发布时间:
                <span>{{ textData.releaseTime }}</span>
              </div>
            </div>
            <div class="operation-box">
              <!-- 复制 -->
              <div class="operation-copy my-caozuo" @click="copy(textData.copy)">
                <span>
                  <span>
                    <img src="../../assets/img/views/fuzhi.png" alt />
                  </span>
                </span>
                <span>复制</span>
              </div>
              <!-- 关键字方案删除 -->
              <div
                v-if="typeDelete == 'm'"
                class="operation-delete my-caozuo"
                @click="deletes(textData)"
              >
                <span>
                  <span>
                    <img src="../../assets/img/views/delete.png" alt />
                  </span>
                </span>
                <span>删除</span>
              </div>
            </div>
          </div>
          <div v-if="dataList.setType == 1" class="main-top-center" style="margin-top: 0px">
            <!-- <p v-html="textData.titleTraslation" class="center-title">{{ textData.titleTraslation }}</p> -->
            <p class="center-title" v-html="textData.title ? textData.title : ''"></p>

            <div class="caozuo-center caozuo-center-title" style="margin-top: 0px">
              <span
                style="margin-left:0;margin-right:16px"
                v-if="textData.isEarlyWarn == '1'"
                class="comment-box-yujing"
              >已预警</span>
              <span style="margin-right: 8px">账号作者:</span>
              {{ textData.author }}
            </div>
            <div class="operation-box operation-box-titles">
              <div class="operation-box-first">
                <div class="operation-box-first-caozuo">
                  是否转推: {{ textData.isRetweeted == 1 ? "是" : "否" }}
                  <span>{{ textData.releaseTime }}</span>
                </div>
                <div class="operation-box-first-caozuo">
                  回复数:
                  <span>{{ textData.replyNum }}</span>
                </div>
                <div class="operation-box-first-caozuo">
                  点赞数:
                  <span>{{ textData.favoriteNum }}</span>
                </div>
                <div class="operation-box-first-caozuo">
                  发布时间:
                  <span>{{ textData.publishTime }}</span>
                </div>
              </div>
              <div class="caozuo-boxs">
                <div style="margin-left: 40px" class="operation-copy my-caozuo">
                  <span>
                    <span>
                      <img src="../../assets/img/views/bluefy.png" alt />
                    </span>
                  </span>
                  <span style="color: #3078ff">推文翻译</span>
                </div>
                <div
                  style="margin-left: 40px"
                  class="operation-copy my-caozuo"
                  @click="copy(textData.copy)"
                >
                  <span>
                    <span>
                      <img src="../../assets/img/views/fuzhi.png" alt />
                    </span>
                  </span>
                  <span>复制</span>
                </div>
                <!-- 定向监测方案删除 -->
                <div
                  style="margin-left: 40px"
                  v-if="typeDelete == 'm'"
                  class="operation-delete my-caozuo"
                  @click="deletes(textData)"
                >
                  <span>
                    <span>
                      <img src="../../assets/img/views/delete.png" alt />
                    </span>
                  </span>
                  <span>删除</span>
                </div>
              </div>
            </div>
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 24px 210px 0 210px">
          <!-- 中间青色位置 -->
          <div class="main-center" v-if="dataList.setType == 0">
            <div class="main-center-left">
              <div class="left-source">
                <span>来源/作者:</span>
                <span v-html="textData.source?textData.source:'无'"></span>
                <span>/</span>
                <span v-html="textData.author?textData.author:'无'"></span>
                <span class="my-caozuo" @click="myCopy(textData.source + '/' + textData.author, 1)">
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-country">
                媒体归属: {{ textData.mediaOwnership }}
                <span
                  class="my-caozuo"
                  @click="myCopy(textData.mediaOwnership, 2)"
                >
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-language">
                语言类型: {{ textData.languageType }}
                <span
                  class="my-caozuo"
                  @click="myCopy(textData.languageType, 3)"
                >
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </div>
            </div>
            <div class="main-center-center">
              <div class="left-source">
                媒体阵营: {{ textData.mediaCamp ? textData.mediaCamp : "无" }}
                <span
                  class="my-caozuo"
                  @click="myCopy(textData.mediaCamp, 4)"
                >
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-language" style="margin-top: 25px">
                关键字:
                <span>
                  <span
                    v-for="(ite, indx) in textData.keywordList"
                    :class="[
                      ite !=
                      textData.keywordList[textData.keywordList.length - 1]
                        ? 'styleMargin'
                        : '',
                    ]"
                    :key="indx"
                  >{{ ite }}</span>
                </span>

                <el-tooltip
                  v-if="textData.keyword&&textData.keywordArry && textData.keywordArry.length > 10"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.keyword"></div>
                  <span class="gengduo-link">(更多)</span>
                </el-tooltip>
                <span class="my-caozuo copy" @click="myCopy(textData.keyword, 6)">
                  <img src="../../assets/img/views/hfz.png" alt />
                </span>
              </div>
            </div>
            <!-- <div class="main-center-right">
							<div class="center-right-img" v-loading="loadingImg" element-loading-text="正在截图中">
								<el-image style="width: 100px; height: 100px" :src="img" :preview-src-list="srcList">
									<div slot="error" class="image-slot">
										<img src="../../assets/img/views/no.jpg" alt />
									</div>
								</el-image>
							</div>
            </div>-->
          </div>
          <!-- <p v-html="textData.contentTraslation" class="main-text">{{ textData.contentTraslation }}</p> -->
          <p
            v-if="dataList.setType == 0"
            class="main-text"
            v-html="textData.content ? textData.content : ''"
          ></p>
          <p
            v-if="dataList.setType == 1"
            class="main-text"
            v-html="textData.twitterContent ? textData.twitterContent : ''"
          ></p>
        </div>
      </div>
    </div>
    <!-- 相似 列表 -->
    <!-- <div class="information-bottom">
      <div class="information-bottom-box">
        <div class="bottom-xiangsi">
          <div style="margin-right: 5px">
            <span class="bottom-xiangsi-one">相似</span>
            <div class="bottom-line"></div>
          </div>
          <span style="font-weight: bold; color: #333333">(12条)</span>
        </div>
        <div class="bottom-count">
          <div class="bottom-count-left" v-for="(item, index) in gengduodata" :key="index">
            <div class="count-left-coution">
              <div
                :class="[
                  'type',
                  'type1',
                  item.num == 1
                    ? 'negative'
                    : item.num == 2
                    ? 'neutral'
                    : 'obverse',
                ]"
              >{{ item.num == 1 ? "负面" : item.num == 2 ? "中性" : "正面" }}</div>

              <div class="type left-website">{{ item.website == "网站" ? "网站" : "电视" }}</div>

              <div v-show="item.yujing" class="left-yujing">已预警</div>
              <div class="left-text">{{ item.title }}</div>
            </div>

            <div class="left-time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>-->
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import deleteDialog from "../../components/deleteDialog";
import {
  selectDataById,
  deleteDataById
} from "../../api/monitoringScheme/index";
import { setLabel } from "../../api/accurate/list";
export default {
  data() {
    return {
      typeDelete: sessionStorage.getItem("abroaddefaultType")
        ? sessionStorage.getItem("abroaddefaultType")
        : "",
      // 是否为定向监测方案
      dataList: {
        setType: this.$route.query.setType,
        dataId: this.$route.query.dataId,
        rank: this.$route.query.rank,
        projectType: 1
      },
      monitorId: this.$route.query.monitorId,
      count: 560,
      textData: {},
      gengduodata: [],
      // 图片的loading
      loadingImg: false,
      srcList: [],
      img: ""
    };
  },
  components: {
    deleteDialog
  },
  methods: {
    // 返回
    goback() {
      this.$router.push("/monitoringScheme/index/abroad");
    },
    // 删除境外信息
    async deleteFn() {
      let data = {
        dataId: Number(this.dataList.dataId),
        projectType: "1",
        rank: this.dataList.rank
      };
      if (this.dataList.rank == "2") {
        data.monitorId = this.monitorId;
      }
      if (this.dataList.setType) {
        data.setType = this.dataList.setType;
      }
      if (this.dataList.setType == 1) {
        data.monitorId = this.monitorId;
      }
      let res = await deleteDataById(data);

      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success"
        });
      } else {
        this.$message.error("删除失败！");
      }
      this.$router.push("/monitoringScheme/index/abroad");
    },
    // 所有复制
    copy(msg) {
      this.$publicFun.copyFun(msg);
    },
    myCopy(msg, type) {
      let str = "";
      msg = msg ? msg : "无";
      if (type == 1) {
        str = `来源/作者:${msg}`;
      } else if (type == 2) {
        str = `媒体归属:${msg}`;
      } else if (type == 3) {
        str = `语言类型:${msg}`;
      } else if (type == 4) {
        str = `媒体阵营:${msg}`;
      } else {
        str = `关键字:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    // 删除
    deletes(item) {
      let obj = {};
      obj.message = "这篇境外新闻";
      obj.setType = this.dataList.setType;
      if (this.dataList.setType == 1) {
        obj.dataId = item.id;
      } else {
        obj.dataId = item.dataId;
      }
      this.$refs.openDialog.openDialog(obj);
    },
    // 获取列表
    async getList() {
      if (this.dataList.rank == "2") {
        this.dataList.monitorId = this.monitorId;
      }
      if (this.dataList.setType == 1) {
        this.dataList.monitorId = this.monitorId;
      }
      const res = await selectDataById(this.dataList);
      if (res.data.data) {
        this.textData = res.data.data;
        if (this.textData.publishTime) {
          this.textData.publishTime = this.textData.publishTime
            .slice(0, 19)
            .replace("T", " ");
        }

        if (this.textData.keyword) {
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
      }

      // this.heightText();
    },
    async setLabel() {
      const res = await setLabel({
        organizationId: this.$store.state.user.organizationId
      });
      this.biaoqianList = res.data.rows;
    }
    // heightText() {
    //   if (this.textData.keyword) {
    //     let word = this.textData.keyword.split("<br/>");
    //     let lineFeed = new RegExp("\\n", "g");
    //     if (this.textData.content) {
    //       this.textData.content = this.textData.content.replace(
    //         lineFeed,
    //         "<br/>"
    //       );
    //     }
    //     word.forEach(ite => {
    //       let reg = new RegExp(ite, "g"); //g代表全部
    //       if (this.textData.title != null) {
    //         this.textData.title = this.textData.title.replace(
    //           reg,
    //           '<span style="color:red">' + ite + "</span>"
    //         );
    //       }
    //       if (this.textData.content != null) {
    //         this.textData.content = this.textData.content.replace(
    //           reg,
    //           '<span style="color:red">' + ite + "</span>"
    //         );
    //       }
    //     });
    //   }
    // }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataList.dataId = params.dataId;
      this.dataList.setType = params.setType;
      this.dataList.rank = params.rank;
      this.monitorId = params.monitorId;
    }
    this.getList();
    this.setLabel();
  }
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.comment-weidu {
  color: #ea3342;
}

.information-box {
  padding: 0 24px 16px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.gengduo-link {
  font-size: 14px;
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../assets/img/views/beijing.png");
}

.center-title {
  max-width: 1000px;
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 8px;
}

.left-website {
  margin-left: 8px;
  background-color: #868bff;
}

.website {
  margin-left: 16px;
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  font-size: 14px;
}

.original-color {
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  font-size: 14px;
  color: #999999;
}

.center-bottom {
  height: 35px;
  margin-top: 16px;
}

.styleMargin {
  margin-right: 5px;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.center-key {
  font-size: 14px;
  color: #999999;
}

.main-text {
  min-height: 400px;
  margin-top: 24px;
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.operation-box img {
  width: 16px;
  height: 16px;
}

.operation-box {
  margin-top: 18px;
  display: flex;
  width: 1137px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  align-items: center;
}

.operation-box > div:not(:first-child) {
  margin-left: 40px;
}

.operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.pinglun-num {
  cursor: pointer;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e59ec;
  position: absolute;
  right: 0px;
}

.main-center {
  padding: 24px 24px 24px 32px;
  display: flex;
  width: 100%;
  height: 156px;
  background: #f4f8fd;
}

.main-center > div {
  font-size: 14px;
  color: #333333;
}

.main-center-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
}

.main-center-center {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.left-language {
  display: flex;
}

.left-language .copy {
  margin-left: 15px;
}

.center-right-img {
  width: 111px;
  height: 108px;
}

.center-right-img img {
  width: 111px;
  height: 108px;
}

.main-center-right {
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.information-bottom {
  padding: 0px 24px 24px 24px;
}

.information-bottom-box {
  padding: 40px 240px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

.bottom-xiangsi-one {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.bottom-xiangsi {
  align-items: center;
  display: flex;
}

.bottom-line {
  width: 42px;
  height: 6px;
  background: linear-gradient(90deg, #4ed2ff 0%, #0d8bff 98%);
}

.bottom-count {
  display: flex;
  flex-wrap: wrap;
}

.bottom-count-left {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.left-yujing {
  padding: 0 8px;
  margin-left: 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
  line-height: 24px;
}

.left-text {
  margin-left: 16px;
  width: 576px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}

.count-left-coution {
  display: flex;
}

.left-time {
  font-size: 14px;
  color: #333333;
}

.english {
  background-color: #3e82ff;
}

.yidu {
  color: #02bc7c;
}

.yishoucang {
  color: #ff9000;
}

.caozuo-boxs {
  display: flex;
}

.operation-box-first {
  display: flex;
}

.operation-box-titles {
  display: flex;
  justify-content: space-between;
}

.operation-box-first-caozuo {
  color: #999999;
  margin-right: 40px;
}

.caozuo-center-title {
  font-weight: 600;
  color: #333333;
  font-size: 18px;
}
.yujing {
  margin-left: 0;
  width: 62px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}
.yujing-box {
  display: flex;
  align-items: center;
}
.my-caozuo {
  position: relative;
  top: 2px;
}
.left-source {
  display: flex;
}
</style>
